<template>
  <!-- 新增提数字通证配置 -->
  <div>
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      :rules="rules"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="数字通证名称" prop="certificateId">
        <el-select
          v-model="ruleForm.certificateId"
          placeholder="请选择数字通证"
          style="width: 150px"
        >
          <el-option label="TEST" value="1"></el-option>
          <el-option label="QJT" value="2"></el-option>
          <el-option label="FXB" value="3"></el-option>
        </el-select>
      </el-form-item>

      <el-row>
        <el-col :span="6">
          <el-form-item label="提数字通证最小值" prop="tokenMin">
            <el-input
              v-model.number="ruleForm.tokenMin"
              onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
              maxlength="8"
              style="width: 150px"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="提数字通证最大值" prop="tokenMax">
            <el-input
              v-model.number="ruleForm.tokenMax"
              onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
              maxlength="8"
              style="width: 150px"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="提数字通证手续费" prop="token_server_change">
            <el-input
              v-model="ruleForm.token_server_change"
              onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
              maxlength="8"
              style="width: 150px"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="买入最小值" prop="">
            <el-input
              v-model.number="ruleForm.buy_min"
              onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
              maxlength="8"
              style="width: 150px"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="卖出最小值" prop="">
            <el-input
              v-model.number="ruleForm.sale_min"
              onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
              maxlength="8"
              style="width: 150px"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="转出手续费类型" prop="out_server_type">
            <el-select
              v-model="ruleForm.out_server_type"
              placeholder="请选择数字通证"
              style="width: 150px"
            >
              <el-option label="TEST" value="1"></el-option>
              <el-option label="QJT" value="2"></el-option>
              <el-option label="FXB" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="6">
          <el-form-item label="买入手续费(交易)" prop="buy_server">
            <el-input
              v-model="ruleForm.buy_server"
              style="width: 150px"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="卖出手续费(交易)" prop="out_server_type">
            <el-input
              v-model="ruleForm.out_server_type"
              style="width: 150px"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="交易手续费类型" prop="bus_server_type">
            <el-select
              v-model="ruleForm.bus_server_type"
              placeholder="请选择数字通证"
              style="width: 150px"
            >
              <el-option label="TEST" value="1"></el-option>
              <el-option label="QJT" value="2"></el-option>
              <el-option label="FXB" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <!-- <el-row>
        <el-col :span="6">
          <el-form-item label="首日涨幅最大比例">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="首日跌幅最大比例">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="非首日涨幅最大比例">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="非首日跌幅最大比例">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
      </el-row> -->
      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
          style="margin-top: 50px"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        certificateId: "",
        tokenMin: "",
        tokenMax: "",
        token_server_change: "",
        buy_min: null,
        sale_min: null,
        out_server_type: "",
        buy_server: "",
        sale_server: "",
        bus_server_type: "",
      },
      rules: {
        certificateId: [
          { required: true, message: "请选择数字通证", trigger: "blur" },
        ],
        tokenMin: [
          {
            required: true,
            message: "请输入提数字通证最小值",
            trigger: "change",
          },
        ],
        tokenMax: [
          {
            required: true,
            message: "请输入提数字通证最大值",
            trigger: "change",
          },
        ],
        token_server_change: [
          {
            required: true,
            message: "请输入提数字通证手续费",
            trigger: "change",
          },
        ],
        out_server_type: [
          {
            required: true,
            message: "请选择手续费类型",
            trigger: "blur",
          },
        ],
        buy_server: [
          {
            required: true,
            message: "请输入买入手续费",
            trigger: "change",
          },
        ],
        out_server_type: [
          {
            required: true,
            message: "请输入卖出手续费",
            trigger: "change",
          },
        ],
        bus_server_type: [
          {
            required: true,
            message: "请选择手续费类型",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios
            .post("/admin/tokenRule/addTokenRule", this.ruleForm)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: "添加成功！",
                  type: "success",
                });
                this.resetForm(formName);
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped></style>
